import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQ from "../../utils"
import questions from "../../accessibilitytestFaq.json"

export default () => (
  <Layout>
    <SEO
      title="Top Accessibility Testing Company in USA - D2i Technology"
      description="D2i Technology is the top accessibility testing company in the USA, providing comprehensive testing services to ensure your website is accessible to all users."
    />
    <div className="text-justify">
      <div className="container py-5 Accessibility-testing-services">
        <div className="row Accessibility-testing-company">
          <div className="col-12 Accessibility-testing-services">
            <div className="section-title Accessibility-testing-services">
              <h1>Accessibility Testing</h1>
            </div>
            <p>
              Accessibility testing is a critical component of usability
              testing, focusing on ensuring digital content (such as websites
              and applications) is usable by individuals with diverse abilities
              and disabilities. Accessibility testing services specialize in
              evaluating digital content for compliance with accessibility
              standards. These evaluations are crucial to ensuring that people
              with disabilities, including those who are blind or visually
              impaired, deaf or hard of hearing, and those with mobility or
              cognitive disabilities, can access and use digital content
              effectively.
            </p>
            <p>
              At{" "}
              <Link to="/" className="text-decoration-underline">
                D2i Technology
              </Link>
              , we recognize the significance of website accessibility
              services&nbsp;
              {/* <Link to="/accessibility-testing/">
              website accessibility services
            </Link> */}
              in enhancing product user experience (UX) and boosting SEO
              performance. Our team of accessibility experts not only identifies
              accessibility issues but also assists in remedying them according
              to WCAG 2.X guidelines, 508 Compliance, and ADA standards.
            </p>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-10">
                <img
                  src="/assets/img/Accessibility testing company.jpeg"
                  className="container"
                  alt="Accessibility testing company to treat all users equally"
                />
              </div>
              <div className="col-1"></div>
            </div>
            <p className="mb-2">
              We offer a comprehensive approach to accessibility testing,
              helping companies achieve inclusive digital products.
              <br />
              Our services include:
            </p>
            <ul className="mb-4">
              <li>
                We use automated tools and expert testers (sighted and
                non-sighted) to find and fix accessibility issues, ensuring
                WCAG, 508, and ADA compliance.
              </li>
              <li>
                Our reports clearly outline identified issues, referencing the
                specific accessibility guidelines that are not met, and provide
                practical recommendations for remediation.
              </li>
              <li>
                Our accessibility engineers can participate in your Software
                Development Life Cycle (SDLC) to proactively identify and
                prevent accessibility barriers from the beginning.
              </li>
              <li>
                We can review existing accessibility certificates and VPAT
                (Voluntary Product Accessibility Template) reports to ensure
                their accuracy and completeness.
              </li>
            </ul>
            
            <p className="mb-2">
              Accessibility ensures everyone can use your product, including
              people with:
            </p>
            <ul className="mb-4">
              <li>Visual Impairments</li>
              <li>Physical Impairment</li>
              <li>Hearing Impairment</li>
              <li>Cognitive Impairment</li>
              <li>Learning Impairment</li>
            </ul>

            <p className="mb-2">
              A good web application should cater to a wide range of users, not
              just those with disabilities. These include:
            </p>
            <ul className="mb-4">
              <li>Users with poor communication infrastructure.</li>
              <li>
                Older people and new users who may be less familiar with
                technology.
              </li>
              <li>
                Users accessing the application on older systems that may not
                support the latest software.
              </li>
              <li>
                Users utilizing non-standard equipment or alternative browsing
                devices.
              </li>
              <li>
                Users with restricted access, such as those in regions with
                limited internet censorship or access limitations.
              </li>
            </ul>

            <p className="mb-2">
              <strong>Tools/Screen Readers</strong>
            </p>
            <ul className="mb-4">
              <li>JAWS (Job Access With Speech)</li>
              <li>NVDA (NonVisual Desktop Access)</li>
              <li>TalkBack (for Android phones)</li>
              <li>VoiceOver (for iOS)</li>
              <li>Microsoft Narrator</li>
              <li>Color Contrast Analyzer</li>
              <li>Dragon Speech Recognition</li>
              <li>ZoomText</li>
            </ul>

            <p>
              Accessibility testing companies can benefit from partnering with
              D2i Technology to ensure their products and services are
              accessible to all users, including those with disabilities. D2i
              Technology specializes in testing websites, software, and mobile
              applications for compliance with accessibility guidelines and
              standards, such as the Web Content Accessibility Guidelines (WCAG)
              2.1. Through comprehensive testing, D2i Technology identifies and
              addresses accessibility barriers, improving the user experience
              for all users and helping companies comply with accessibility laws
              and regulations.
            </p>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-10">
                <img
                  src="/assets/img/accessibility testing services.jpeg"
                  className="container"
                  alt="Accessibility is all about removing barriers and providing the benefits of technology for everyone"
                />
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
        <div className="FAQ">{FAQ(questions)}</div>
      </div>
    </div>
  </Layout>
)
